import React from "react"
import { Line } from 'react-chartjs-2'


export const PopularityGraph = ({ value, color = "#cc0000" }) => {
    const fullLength = 50
    const barThickness = 3
    const emptyColor = "lightgrey"

    return (
        <div style={{ marginRight: "10px" }}>
            <svg width={fullLength} height={barThickness}>
                <rect
                    rx={barThickness / 2} ry={barThickness / 2}
                    width={fullLength}
                    height={barThickness}
                    fill={emptyColor}
                />
                <rect
                    rx={barThickness / 2} ry={barThickness / 2}
                    width={value * fullLength}
                    height={barThickness}
                    fill={color}
                />
            </svg>
        </div>
    )
}

export const TrendGraph = ({ births, color = "#cc0000", big = false }) => {
    const chartData = {
        labels: births.map(record => record.years),
        datasets: [
            {
                data: births.map(record => record.births)
            }
        ]
    }

    let options = {
        layout: {
            padding: {
                top: 3,
                bottom: 3,
                left: 2,
                right: 2
            },
            margin: {
                top: 0,
                bottom: 0,
                left: 10,
                right: 10,
            }
        },
        responsive: false,
        legend: {
            display: false
        },
        elements: {
            line: {
                borderColor: color,
                borderWidth: 3,
                backgroundColor: "rgba(0, 0, 0, 0.05)"
            },
            point: { radius: 0 }
        },
        tooltips: { enabled: false },
        scales: {
            yAxes: [
                {
                    display: false,
                    gridLines: { display: false }
                }
            ],
            xAxes: [
                {
                    display: false,
                    gridLines: { display: false }
                }
            ]
        }
    }

    let props = { height: 25, width: 50 }

    if (big) {
        options.scales.xAxes[0].display = true
        options.scales.yAxes[0].display = true
        options.responsive = true
        props = {}
    }

    return (
        <Line data={chartData} {...props} options={options} />
    )
}

