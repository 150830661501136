import React from 'react'
import { Link } from 'gatsby'
import { PopularityGraph, TrendGraph } from './graphs'



export const AttributeIcon = ({ attribute, size = 30, color = "#72a69a" }) => {
    //const seed = Math.random()*360
    color = "var(--neutral-color)" //"hsl("+seed+", 48%, 67%)"

    const backgroundImage = ["webp", "png"]
        .map(format => `url(${attribute.imagePath}.${format})`)
        .join(", ")

    return (attribute.imagePath !== '' ?
        <div
            src={attribute.imagePath}
            style={{
                width: size + "px",
                height: size + "px",
                borderRadius: "50%",
                backgroundImage: backgroundImage,
                backgroundSize: "cover",
            }}

            alt={attribute.title}
        />
        :
        <svg height={size} width={size}>
            <circle cx="50%" cy="50%" r={(size / 2 - size / 25) * 0.9}
                alignmentBaseline="middle"
                fill={color} />
            <text x="50%" y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                fill="white"
                fontFamily="Neutra Text, Sans Serif"
                fontSize={Math.floor(size / 2 / Math.pow(attribute.id.length, 0.1))}
                fontWeight="bold">
                {attribute.id.slice(0, 3)}
            </text>
        </svg>
    )
}



export const DetailedAttribute = ({ attribute, color = "#cc0000", show_category = true }) => (
    <div style={{ margin: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
            <AttributeIcon attribute={attribute} />
            <h3 style={{ margin: "5px" }}>
                <Link to={"/" + attribute.associated_page_url}>
                    {show_category ? attribute.category + ' ' : ''}{attribute.title}
                </Link>
            </h3>
        </div>
        <div style={{ paddingLeft: "10px", margin: "10px" }}>
            <div style={{ height: "30px", display: "flex", alignItems: "center" }}>
                <PopularityGraph value={attribute.popularity} color={color} />
                <span style={{ marginLeft: "15px" }}>#{attribute.rank} {attribute.category}</span>
            </div>
            <div style={{ height: "30px", display: "flex", alignItems: "center" }}>
                <TrendGraph births={attribute.births} color={color} />
                <span style={{ marginLeft: "15px" }}>{parseInt(attribute.latest_births)} Geburten</span>
            </div>
        </div>
    </div>
)


export const DetailedAttributeList = ({ attributes, show_category = true }) => (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
        {attributes
            .filter(attribute => attribute.category !== "Geschlecht")
            .map(attribute => (
                <DetailedAttribute key={attribute.id} attribute={attribute} show_category={show_category} />
            ))}
    </div>
)


export const AttributeList = ({ attributes }) => (
    <div style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px 0px",
    }}>
        {attributes.map(attr => (
            <Link key={attr.id} to={"/" + attr.associated_page_url} style={{
                display: "flex",
                alignItems: "center",
                marginRight: "15px",
            }}>
                <AttributeIcon attribute={attr} size={20} />
                <span style={{ marginLeft: "5px" }}>{attr.title}</span>
            </Link>
        ))}
    </div>
)


export const AttributeTable = ({ attributes, color }) => (
    <div width="100%">
        {attributes.sort((a1, a2) => a1.latest_births > a2.latest_births ? -1 : 1)
            .map(attr => (
                <div key={attr.id}
                    style={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <AttributeIcon attribute={attr} size={20} color={color} />
                    <Link style={{ marginLeft: "10px" }} to={"/" + attr.associated_page_url}>{attr.title}</Link>
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                        <PopularityGraph value={attr.popularity} color={color} />
                        <TrendGraph births={attr.births} color={color} />
                    </div>
                </div>
            ))}
    </div>
)

export const GroupedAttributeTable = ({ attributes, color }) => {
    const groupedAttributes = {
        "Herkünfte": attributes.filter(attribute => attribute.category === 'Herkunft'),
        "Bedeutungen": attributes.filter(attribute => attribute.category === 'Bedeutung'),
        "Schreibweise": attributes.filter(attribute =>
            ['Drei Anfangsbuchstaben', 'Zwei Anfangsbuchstaben', 'Anfangsbuchstaben', 'Silben', 'Länge', 'Endend mit']
                .includes(attribute.category)),
        "Beliebtheit": attributes.filter(attribute => attribute.category === 'Beliebtheit')
    }
    return (
        <div>
            {Object.keys(groupedAttributes)
                .filter(group => groupedAttributes[group].length > 0)
                .map(group => (
                    <div>
                        <h2>{group}</h2>
                        <AttributeTable attributes={groupedAttributes[group]} color={color} />
                    </div>
                ))}
        </div>
    )
}

