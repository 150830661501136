import React, { useEffect } from "react"
import './layout.css'
import { Navigation } from "./navbar"
import Footer from "../components/footer"

import styled from "styled-components"
import { RecoilRoot } from "recoil"
import { FavoritesLocalStorageSync } from "../components/favorites"
import Helmet from "react-helmet"
import Ad from "../components/ad"


const MainFrame = styled.div`
    background-color: #E5E5E5;

    display: grid;
    grid-template-rows: auto 1fr 50px;
    grid-template-areas: "header" "content" ".";
    height: 100vh;
    width: 100vw;

    @media (min-width: 750px) {
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr minmax(300px, 994px) auto 1fr;
        grid-template-areas: ". header ad-col ." ". content ad-col .";
    }
`

const ContentFrame = styled.div`
    grid-area: content;
    overflow: hidden scroll;

    display: flex;
    flex-direction: column;
    align-items: stretch;
`

const AdCol = styled.div`
    grid-area: ad-col;
    width: 300px;
    

    @media (max-width: 1100px) {
        display: none;
    }
`


export default function Layout({ children }) {
    useEffect(() => {
        try { window.audienzz.refreshAds() } catch (e) { }
    }, [])
    return (
        <RecoilRoot>
            <FavoritesLocalStorageSync />
            <Helmet>
                <script src="https://adnz.co/header.js?adTagId=100"></script>
            </Helmet>
            <MainFrame>
                <Navigation />
                <ContentFrame>
                    {children}
                    <Footer />
                </ContentFrame>
                <AdCol>
                    <Ad id="adnz_halfpage_1" />
                </AdCol>
            </MainFrame>
        </RecoilRoot>

    )
}