import React from "react"
import logo from "../../static/logos/swissmom-logo.webp"
import styled from "styled-components"

const FooterFrame = styled.div`
    margin-top: 3px;
    border-top: 1px solid #dddddd;
    background-color: white;
    padding: 15px;
    gap: 15px 40px;

    display: flex;
    flex-wrap: wrap;
    

    div:nth-child(1) {
        color: darkgrey;
        font-size: large;
        span {
            margin-right: 10px;
        }
    }

    div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 30px;
    }
`

export default () => (
    <FooterFrame>
        <div>
            <span>Ein Projekt von</span>
            <a href="https://www.swissmom.ch" target="_blank" rel="noreferrer">
                <img src={logo} height="16px" alt="swissmom logo" />
            </a>
        </div>
        <div>
            <a href="https://www.swissmom.ch/impressum/datenschutzrichtlinien" target="_blank" rel="noreferrer">Datenschutzbestimmungen</a>
            <a href="https://www.swissmom.ch/impressum" target="_blank" rel="noreferrer">Impressum</a>
            <a href="https://www.swissmom.ch/kontakt" target="_blank" rel="noreferrer">Kontakt</a>
        </div>

        <span>Copywrite © {new Date().getFullYear()} swissmom</span>
    </FooterFrame>
)
