import React, { useEffect } from "react"
import { atom, useRecoilState } from "recoil"

import { MdFavorite, MdFavoriteBorder } from "react-icons/md"


const favorites = atom({
    key: "favorites",
    default: []
})

export default favorites


export const FavoritesButton = ({ name, size = {}, color = "#cc0000" }) => {
    const [names, setNames] = useRecoilState(favorites)

    const addName = () => {
        setNames([...names, name])
    }

    const removeName = () => {
        setNames(names.filter(item => item.id !== name.id))
    }

    return (
        <>
            {names.filter(item => item.id === name.id).length > 0 ?
                <MdFavorite color={color} size={size} onClick={removeName} style={{ cursor: "pointer" }} />
                :
                <MdFavoriteBorder color={color} size={size} onClick={addName} style={{ cursor: "pointer" }} />
            }
        </>
    )
}


export const FavoritesLocalStorageSync = () => {
    const [names, setNames] = useRecoilState(favorites)

    const loadFromLocalStorage = () => {
        const namesInStorage = JSON.parse(localStorage.getItem('favorites'))
        namesInStorage && setNames(namesInStorage)
    }

    const updateLocalStorage = () => {
        localStorage.setItem('favorites', JSON.stringify(names))
    }

    useEffect(loadFromLocalStorage, []) // On load
    useEffect(updateLocalStorage, [names]) // update Localstorage after chagnes

    return (null)
}


