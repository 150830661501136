import React, { useState } from "react"
import { Link } from "gatsby"
import { GiBookmarklet, GiWorld } from "react-icons/gi"
import { IoMdTransgender, IoIosBulb, IoMdPodium, IoIosSearch } from "react-icons/io"
import { MdFavorite } from "react-icons/md"

import logo from "../../static/logos/swissmom-vornamen-logo.webp"
import styled from "styled-components"


const categoryItems = [
    {
        title: "Geschlecht",
        href: "/nach-geschlecht",
        icon: IoMdTransgender
    },
    {
        title: "Herkunft",
        href: "/nach-herkunft",
        icon: GiWorld
    },
    {
        title: "Bedeutung",
        href: "/nach-bedeutung",
        icon: IoIosBulb,
    },
    {
        title: "Beliebtheit",
        href: "/nach-beliebtheit",
        icon: IoMdPodium
    },
    {
        title: "Lexikon",
        href: "/namenslexikon",
        icon: GiBookmarklet
    }
]

const actionItems = [
    {
        title: "Suche",
        href: "/suche",
        icon: IoIosSearch
    },
    {
        title: "Favoriten",
        href: "/favoriten",
        icon: MdFavorite
    }
]

const NavFrame = styled.div`
    grid-area: header;
    z-index: 999;

    display: flex;    
    align-items: center;
    justify-content: space-between;

    &, > * {
        background-color: #cc0000;
        color: white;
    }

    > * {
        padding: 10px;

        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    

    @media (max-width: 750px) {
        .category-items {
            position: fixed;
            width: 100vw;
            left: 0;
            bottom: 0; 
        }
    }

    @media (min-width: 750px) {
        font-size: 120%;
        > * > * {
            margin: 0 10px;
        }
    }
   
`

export const Navigation = () => (
    <NavFrame>
        <Logo />
        <CategoryItems />
        <ActionItems />
    </NavFrame>
)

const Logo = () => (
    <Link to="/" className="logo">
        <img
            src={logo}
            alt='Swissmom Logo'
            height='25px'
        />
    </Link>
)

const ActionItems = () => (
    <div className="action-items">
        {actionItems.map(item =>
            <MenuItem key={item.href} item={item} size={15} />)}
    </div>
)

const CategoryItems = () => (
    <div className="category-items">
        {categoryItems.map(item => <MenuItem key={item.href} item={item} size={15} />)}
    </div>
)


const MenuItem = ({ item, size = 25 }) => {
    const Icon = item.icon
    const zoomFactor = 1.15
    const [zoom, setZoom] = useState(false)


    return (
        <Link
            key={item.href}
            to={item.href}
            style={{
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px 15px",
                width: size / 25 * 100 + "px",
                fontSize: size / 25 * 100 + "%",
                fontWeight: zoom ? "200" : "100",
                textDecoration: "none"
            }}
            onMouseEnter={() => { setZoom(true) }}
            onMouseLeave={() => { setZoom(false) }}
        >
            <div style={{
                display: "flex",
                height: size * zoomFactor + "px",
                alignItems: "center",
            }}>
                <Icon
                    size={zoom ? size * zoomFactor : size}
                    style={{ margin: "auto" }}
                />
            </div>
            {item.title}
        </Link>
    )
}





